import React, { useEffect, useState } from 'react'
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { token, type, userid } from '../../common/AdminAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPen, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TelemarketerList = () => {
    const history = useHistory();
    const [chainList, setChainList] = useState([]);
    const [order, setOrder] = useState('ASC');
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);

    const [error, seterror] = useState({
        errarStatus: false,
        errorMessage: "",
        bgcolor: "",
    });

    useEffect(() => {
        getChainList();
    }, []);

    const getChainList = async () => {
        let data = {
            user_id: userid,
            method: "retrieve_tmid_admin",
            token: token,
            user_type: type
        }
        try {
            let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            let result = await response.json();

            if (result.success) {
                // console.log(result.data)
                setChainList(result.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const EditEntityID = (id, username, tmid, chainid, editstatus, telemarketerstatus) => {
        history.push(`/admin/edit-telemarketer/${id}/${username}/${tmid}/${editstatus}/${telemarketerstatus}`)
    }

    const sorting = (col) => {
        if (order === 'ASC') {
            const sorted = [...chainList].sort((a, b) =>
                a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
            )
            setChainList(sorted);
            setOrder('DSC')
        }
        if (order === 'DSC') {
            const sorted = [...chainList].sort((a, b) =>
                a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
            )
            setChainList(sorted);
            setOrder('ASC')
        }
    }

    const GoTelemarketer = () => {
        history.push("/admin/add-telemarketer")
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = chainList?.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(chainList?.length / recordsPerPage);

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <AdminSideMenu />

                <div className="layout-page">
                    <AdminTopMenu />

                    <div className="content-wrapper">
                        <div className="container-xxl container-p-y">
                            <div className="row">

                                <div className="col-lg-12 col-md-4 order-1">
                                    <div className="">
                                        <div className='row'>
                                            <div className='col-md-6'></div>
                                            <div className='col-md-6'>
                                                <button type="button" onClick={GoTelemarketer} className="btn btn-secondary float-right mb-3">Add Telemarketer</button>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <h5 className="card-header">Telemarketer List</h5>
                                                </div>
                                               {chainList.length > 0 &&
                                                <div className='col-md-6'>
                                                    <div className="input-group mt-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            id="searchInput"
                                                            name="searchdata"
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                            className="form-control mr-2"
                                                            placeholder="Search Message"
                                                        />
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="table-responsive text-nowrap">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Username</th>
                                                            <th>Tele Marketer ID</th>
                                                            <th>Tele Marketer Type </th>
                                                            <th>Status </th>
                                                            <th style={{ cursor: "pointer" }} onClick={() => sorting('created')}>Created Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-border-bottom-0">
                                                        {currentRecords?.filter((x) => {
                                                            return search.toLowerCase() === '' ? x : x.entity_tm_id.toLowerCase().includes(search);
                                                        }).map((items, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    <span style={{ cursor: "pointer" }} onClick={() => EditEntityID(items.id, items.system_id, items.entity_tm_id, items.chainid, items.status, items.delivery_partner)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                                                    {/* <span onClick={() => this.openDialog(item.id, item.entity_id)} className="badge bg-danger">
                                    <FontAwesomeIcon icon={faTrash} />
                                  </span> */}
                                                                </td>
                                                                <td>{items.system_id}</td>
                                                                <td> {items.entity_tm_id}</td>
                                                                <td>{items.delivery_partner === 1 ? <span>Delivery Partner</span> : <span>Aggregator</span>}</td>
                                                                <td>
                                                                    {items.status === 0 ? (
                                                                        <span className="badge badge-sm bg-danger mr-2">
                                                                            Inactive{" "}
                                                                        </span>
                                                                    ) : (
                                                                        <span className="badge badge-sm bg-success mr-2">
                                                                            Active{" "}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>{items.created}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <ul className="pagination mt-2">
                                                {Array.from({ length: totalPages }).map((_, index) => (
                                                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => handlePagination(index + 1)}>
                                                            {index + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TelemarketerList
