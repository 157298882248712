import React, { useEffect, useState } from 'react'
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { token, type, userid } from '../../common/AdminAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faPen, faSearch, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const UploadList = () => {
    const history = useHistory();
    const [search,setSearch] = useState('')
    const [dataList, setDataList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);


    useEffect(() => {
        retrieveList();
    }, [])


    const retrieveList = async () => {
        let data = {
            user_id: userid,
            method: "retrieve_chain_admin",
            token: token,
            user_type: type
        }
        try {
            let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            let result = await response.json();

            if (result.success) {
                // console.log(result.data)
                setDataList(result.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const EditList = (id,username, tmid, entityid, chainmapping, chainid, DeliveryPatnerId) => {
        history.push(`/admin/upload-edit/${id}/${username}/${tmid?.trim()}/${entityid}/${chainmapping}/${chainid}/${DeliveryPatnerId}`)
    }

    const GoUploadChain = () =>{
        history.push("/admin/upload")
    }

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = dataList?.slice(indexOfFirstRecord, indexOfLastRecord);
    const totalPages = Math.ceil(dataList?.length / recordsPerPage);

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    console.log(dataList)

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <AdminSideMenu />

                <div className="layout-page">
                    <AdminTopMenu />

                    <div className="content-wrapper">
                        <div className="container-xxl container-p-y">
                            <div className="row">

                                <div className="col-lg-12 col-md-4 order-1">
                                    <div className="">
                                        <div className='row'>
                                            <div className='col-md-6'></div>
                                            <div className='col-md-6'>
                                                <button type="button" onClick={GoUploadChain} className="btn btn-secondary float-right mb-3">Upload Chain</button>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <h5 className="card-header">Chain List</h5>
                                                </div>
                                                {dataList.length > 0 &&
                                                <div className='col-md-6'>
                                                    <div className="input-group mt-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                                                        </div>
                                                        <input
                                                            type="text"
                                                            id="searchInput"
                                                            name="searchdata"
                                                            value={search}
                                                            onChange={(e) => setSearch(e.target.value)}
                                                            className="form-control mr-2"
                                                            placeholder="Search Message"
                                                        />  
                                                    </div>
                                                </div>}
                                            </div>
                                            <div className="table-responsive text-nowrap">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Action</th>
                                                            <th>Username</th>
                                                            <th>Tele Marketer ID</th>
                                                            <th>Sender Entity ID</th>
                                                            <th>Sender Chain ID</th>
                                                            <th>Mapping</th>
                                                            <th>TMDF1</th>
                                                            <th>Status </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-border-bottom-0">
                                                        {currentRecords?.filter((x)=>{
                                                            return(search.toLowerCase() === '' ? x : x.system_id.toLowerCase().includes(search) || 
                                                            search.toLowerCase() === '' ? x : x.system_id_tmid.toLowerCase().includes(search) ||
                                                            search.toLowerCase() === '' ? x : x.sender_entity_id.toLowerCase().includes(search) ||
                                                            search.toLowerCase() === '' ? x : x.sender_chain_id.toLowerCase().includes(search) 
                                                        )
                                                        }).map((items, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    <span style={{ cursor: "pointer" }} onClick={() => EditList(items.id,items.system_id, items.system_id_tmid, items.sender_entity_id, items.chain_mapping, items.sender_chain_id, items.TMDF1)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>
                                                                </td>
                                                                <td>{items.system_id}</td>
                                                                <td> {items.system_id_tmid}</td>
                                                                <td>{items.sender_entity_id}</td>
                                                                <td>{items.sender_chain_id}</td>
                                                                <td>{items.chain_mapping}</td>
                                                                <td>{items.TMDF1}</td>
                                                                <td>
                                                                    {items.status === 0 ? (
                                                                        <span className="badge badge-sm bg-danger mr-2">
                                                                            Inactive{" "}
                                                                        </span>
                                                                    ) : (
                                                                        <span className="badge badge-sm bg-success mr-2">
                                                                            Active{" "}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>                                               
                                            </div>                                           
                                        </div>
                                        <ul className="pagination mt-2">
                                                {Array.from({ length: totalPages }).map((_, index) => (
                                                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                        <button className="page-link" onClick={() => handlePagination(index + 1)}>
                                                            {index + 1}
                                                        </button>
                                                    </li>
                                                ))}
                                            </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadList
