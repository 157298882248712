import React, { useState, useEffect } from "react";
import DashboardSideMenu from "../../navbar/DashboardSideMenu";
import DashboardTopMenu from "../../navbar/DashboardTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { userid, token, type } from "../../common/UserAuth";
import "react-datepicker/dist/react-datepicker.css";

const ManagementChain = () => {
  const [dataItem, setDataItem] = useState({});
  const [tmid,setTmId] = useState('')
  const [status, setStatus] = useState(1);
  const [chainList, setChainList] = useState([]);
  const [id, setId] = useState('');
  const [order, setOrder] = useState('ASC');
  const [selectedOption, setSelectedOption] = useState('');

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });

  const handleChange = (e) => {
    setTmId(e.target.value);
    seterror({errarStatus: false})
  }

  const handleStatusChange = (e) => {
    setStatus(e.target.value)
  }

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
    seterror({ errarStatus: false });
  };

  useEffect(() => {
    getChainList();
  }, []);

  const getChainList = async () => {
    let data = {
      user_id: userid,
      method: "retrieve",
      token: token,
      ret_user_id: userid,
      user_type: type
    }
    try {
      let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      let result = await response.json();

      if (result.success) {
        // console.log(result.data)
        setChainList(result.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isValid = () => {
    if (tmid === undefined || tmid === "") {
      seterror({
        errarStatus: true,
        errorMessage: "Telemarketer ID should not be empty",
        bgcolor: "aler alert-danger",
      });
      return false
    }
    if (tmid < 8) {
      seterror({
        errarStatus: true,
        errorMessage: "the Length should be gretaer than 8",
        bgcolor: "aler alert-danger",
      });
      return false
    }
    // if (dataItem.chainid === undefined || dataItem.chainid === "") {
    //   seterror({
    //     errarStatus: true,
    //     errorMessage: "Please Enter Chain ID",
    //     bgcolor: "aler alert-danger",
    //   });
    //   return false
    // }
    if (!selectedOption) {
      seterror({
        errarStatus: true,
        errorMessage: "Either Aggregator or Delivery Partner should be choose",
        bgcolor: "aler alert-danger",
      });
      return false
    }
    else {
      return true;
    }
  }

  const CreateChain = async () => {
    if (isValid()) {
      let data = {
        tmid: tmid,
        chainid: dataItem.chainid ? dataItem.chainid : "",
        tmtype: selectedOption,
        status: status,
        user_id: userid,
        method: "add_tmid",
        token: token,
        ret_user_id: userid,
        user_type: type
      }
      try {
        let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        let result = await response.json();
        if (result.success) {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "aler alert-success",
          });
          setTimeout(() => {
            seterror({ errarStatus: false });
          }, 2000)
          // setDataItem({
          //   tmid: "",
          //   chainid: "",
          // });
          setTmId('')
          setStatus(1);
          getChainList();
        }
        else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
          setTimeout(() => {
            seterror({ errarStatus: false });
          }, 2000)
        }

      } catch (error) {
        console.log(error)
      }
    }
  }

  const EditEntityID = (id, tmid, chainid, status, telemarketerstatus) => {
    setId(id);
    setTmId(tmid)
    setStatus(status)
    setSelectedOption(telemarketerstatus.toString());
  }

  const UpdateChain = async () => {
    if (isValid()) {
      let data = {
        id: id,
        tmid: tmid,
        chainid: dataItem.chainid,
        tmtype: selectedOption,
        status: status,
        user_id: userid,
        method: "update_tmid",
        token: token,
        ret_user_id: userid,
        user_type: type
      }
      try {
        let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        })
        let result = await response.json();
        if (result.success) {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "aler alert-success",
          });
          // setDataItem({
          //   tmid: "",
          //   chainid: "",
          // });
          setTmId('')
          setStatus(1);
          setId('')
          setTimeout(() => {
            seterror({ errarStatus: false });
          }, 2000)
          getChainList();
        }
        else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
          setTimeout(() => {
            seterror({ errarStatus: false });
          }, 2000)
        }

      } catch (error) {
        console.log(error)
      }
    }
  }

  const sorting = (col) => {
    if (order === 'ASC') {
      const sorted = [...chainList].sort((a, b) =>
        a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1
      )
      setChainList(sorted);
      setOrder('DSC')
    }
    if (order === 'DSC') {
      const sorted = [...chainList].sort((a, b) =>
        a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1
      )
      setChainList(sorted);
      setOrder('ASC')
    }
  }

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <DashboardSideMenu />
        <div className="layout-page">
          <DashboardTopMenu />
          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                {chainList?.length && !id > 0 ? null :
                  <div className="col-xl-12">
                    <div className="card mb-4">
                      <h5 className="card-header">Manage TMID</h5>
                      <div className="card-body">
                        {error.errarStatus ? (
                          <div className={error.bgcolor} role="alert">
                            <strong>Alert!</strong> {error.errorMessage}
                            <button
                              type="button"
                              className="close"
                              data-dismiss="alert"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                        ) : null}
                        <div className="row gy-3">
                          <div className="col-md-6">
                            <label>Tele Marketer ID</label>
                            <input type="text" name="tmid" disabled={id ? true : false} maxLength={20} value={tmid} onChange={handleChange} placeholder="Tele Marketer ID" className="form-control" min="0" step="1" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }} />
                          </div>

                          {/* <div className={chainList.length > 0 ? "col-md-6" : "col-md-4"}>
                          <label>Chain ID</label>
                          <input type="text" name="chainid" defaultValue={dataItem.chainid} onChange={handleChange} placeholder="Chain ID" className="form-control" />
                        </div> */}

                          <div className="col-md-6">
                            <label>Status</label>
                            <div className="input-group">
                              <select className="form-select" value={status} onChange={handleStatusChange}>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row gy-3 mt-2">
                          <div className="col-md-6">
                            <label>
                              <input
                                type="radio"
                                value="0"
                                checked={selectedOption === '0'}
                                onChange={handleRadioChange}
                              />
                              <span className="ml-1">Aggregator</span>
                            </label>

                            <label>
                              <input
                                type="radio"
                                value="1"
                                checked={selectedOption === '1'}
                                onChange={handleRadioChange}
                                className="ml-4"
                              />
                              <span className="ml-1">Delivery Partner</span>
                            </label>
                          </div>
                        </div>

                        <div className="row gy-3 justify-content-center mt-2">
                          <div className="col-md-4">
                            {id ?
                              <div className="input-group">
                                <button
                                  onClick={UpdateChain}
                                  className="btn btn-block btn-primary me-1"
                                >
                                  <span className="mr-2"><FontAwesomeIcon icon={faPen} /></span>
                                  Update
                                </button>
                              </div>
                              :
                              <div className="input-group">
                                <button
                                  onClick={CreateChain}
                                  className="btn btn-block btn-primary me-1"
                                >
                                  <span className="mr-2"><FontAwesomeIcon icon={faPlus} /></span>
                                  Create
                                </button>
                              </div>}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>}

                {chainList?.length > 0 &&
                <div className="col-lg-12 col-md-4 order-1">
                  <div className="">
                    <div className="card">
                      <h5 className="card-header">Telemarketer List</h5>
                      <div className="table-responsive text-nowrap">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Tele Marketer ID</th>
                              <th>Tele Marketer Type </th>
                              <th>Status </th>
                              <th style={{ cursor: "pointer" }} onClick={() => sorting('created')}>Created Date</th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {chainList?.map((items, i) => (
                              <tr key={i}>
                                <td>
                                  <span style={{ cursor: "pointer" }} onClick={() => EditEntityID(items.id, items.entity_tm_id, items.chainid, items.status, items.delivery_partner)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>

                                  {/* <span onClick={() => this.openDialog(item.id, item.entity_id)} className="badge bg-danger">
                                    <FontAwesomeIcon icon={faTrash} />
                                  </span> */}
                                </td>
                                <td> {items.entity_tm_id}</td>
                                <td>{items.delivery_partner === 1 ? <span>Delivery Partner</span> : <span>Aggregator</span>}</td>
                                <td>
                                  {items.status === 0 ? (
                                    <span className="badge badge-sm bg-danger mr-2">
                                      Inactive{" "}
                                    </span>
                                  ) : (
                                    <span className="badge badge-sm bg-success mr-2">
                                      Active{" "}
                                    </span>
                                  )}
                                </td>
                                <td>{items.created}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementChain;
