import React, { useState, useEffect } from "react";
import DashboardSideMenu from "../../navbar/DashboardSideMenu";
import DashboardTopMenu from "../../navbar/DashboardTopMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faEdit, faPen, faPlus, faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/Url";
import { userid, token, type, serverip } from "../../common/UserAuth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'

const UploadChain = () => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(50);

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });
  const [id, setID] = useState('');
  const [chainList, setChainList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [csvFile, setcsvFile] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [insideFileData, setInsideFileData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [tmID, setTmID] = useState('');
  const [senderchainID, setSenderChainID] = useState('');
  const [senderEntityID, setSenderEntityID] = useState('');
  const [mapping, setMapping] = useState('');
  const [deliveryPartnerId,setDeliveryPartnerId] = useState('')

  // const userHandler = (selectedOption) => {
  //   setSelectedUser(selectedOption);
  //   seterror({ errarStatus: false })
  // };

  const userHandler = (e) => {
    setTmID(e.target.value);
    seterror({ errarStatus: false })
  };

  const options = chainList.map((x) => ({
    value: x.entity_tm_id,
    label: x.entity_tm_id
  }));

  useEffect(() => {
    retrieveList();
    getChainList();
  }, [])

  const getChainList = async () => {
    let data = {
      user_id: userid,
      method: "retrieve",
      token: token,
      ret_user_id: userid,
      user_type: type
    }
    try {
      let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      let result = await response.json();

      if (result.success) {
        // console.log(result.data)
        setChainList(result.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split("\n").map((row) => row.trim()).filter(row => row.length > 0);  // Trim and remove empty rows

        // Optional: Process rows to extract headers and values
        const headers = rows[0].split(","); // Assuming the first row is the header
        const data = rows.slice(1).map((row) => {
          const values = row.split(",");
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = values[index];
          });
          return obj;
        });

        // console.log(data);
        setInsideFileData(data);
        setCsvData(file);
        setcsvFile(true); // Send data to parent component
      };

      reader.readAsText(file);
    }

    seterror({ errarStatus: false });
  };


  const retrieveList = async () => {
    let data = {
      user_id: userid,
      method: "retrieve_chain",
      token: token,
      ret_user_id: userid,
      user_type: type
    }
    try {
      let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      let result = await response.json();

      if (result.success) {
        // console.log(result.data)
        const data = result.data;
       const newData =  data.map((x)=>{
        return({...x, system_id_tmid: x.system_id_tmid.trim()})
       })
        setDataList(newData)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isValid = () => {
    // if (!selectedUser) {
    //   seterror({
    //     errarStatus: true,
    //     errorMessage: "Entity/TM ID should not be empty",
    //     bgcolor: "alert alert-danger",
    //   });
    //   return false
    // }
    if (!tmID) {
      seterror({
        errarStatus: true,
        errorMessage: "Telemarketer ID should not be empty",
        bgcolor: "alert alert-danger",
      });
      return false
    }
    if (csvData.length === 0) {
      seterror({
        errarStatus: true,
        errorMessage: "Upload Csv Should not be empty",
        bgcolor: "alert alert-danger",
      });
      return false
    }
    // if (insideFileData.map((x) => x.EID === '' || x.EID === undefined) || insideFileData.map((x) => x.CHAINID === '' || x.CHAINID === undefined)) {
    //   seterror({
    //     errarStatus: true,
    //     errorMessage: "Telly marketer id and cahin id Should not be empty",
    //     bgcolor: "alert alert-danger",
    //   });
    //   return false
    // }
    else {
      return true;
    }
  }

  const UploadCsv = async () => {
    if (isValid()) {
      let formData = new FormData();

      const chainItem = chainList?.find(x => x.entity_tm_id === tmID);
      if (chainItem) {
        formData.append('tmid', `${tmID} & ${chainItem.delivery_partner}`);
      } else {
        formData.append('tmid', null);
      }
      formData.append('amdfile', csvData);
      formData.append('user_id', userid);
      formData.append('method', 'upload_chain');
      formData.append('token', token);
      formData.append('ret_user_id', userid);
      formData.append('user_type', type);

      try {
        let response = await fetch("https://smsc.datagenit.com/apismpp/v1/dlt_setup.php", {
          method: 'POST',
          body: formData,
        });

        let result = await response.json();
        if (result.success) {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-success",
          });
          setTimeout(() => {
            seterror({ errarStatus: false });
          }, 3000);
          retrieveList();
          setTmID('');
          setCsvData('');
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const EditList = (id, tmid, entityid, chainmapping, chainid, tmdf1) => {
    setID(id);
    setTmID(tmid);
    setSenderEntityID(entityid);
    setSenderChainID(chainid);
    setMapping(chainmapping);
    setDeliveryPartnerId(tmdf1);
  }

  const UpdateList = async () => {
    // const chainItem = chainList?.find(x => x.entity_tm_id === senderEntityID);
    let data = {
      tmid: tmID,
      sender_entity_id: senderEntityID,
      sender_chain_id: senderchainID,
      mapping: mapping,
      tmdf1: deliveryPartnerId,
      id: id,
      method: "update_chain",
      token: token,
      ret_user_id: userid,
      user_type: type,
      user_id: userid,
    }
    try {
      let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      let result = await response.json();
      if (result.success) {
        seterror({
          errarStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-success",
        });
        setTimeout(() => {
          seterror({
            errarStatus: false,
          }, 3000);
        })
        retrieveList();
        setID('');
        setTmID('');
        setSenderChainID('');
        setSenderEntityID('');
        setMapping('');
        setDeliveryPartnerId('')
      }
      else {
        seterror({
          errarStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-danger",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = dataList?.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(dataList?.length / recordsPerPage);

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <DashboardSideMenu />
        <div className="layout-page">
          <DashboardTopMenu />
          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card mb-4">
                    <div className="row">
                      <div className="col-6">
                        <h5 className="card-header">Upload Chain</h5>
                      </div>
                      {!id &&
                        <div className="col-6">
                          <button
                            onClick={() => window.location.href = "https://smsc.datagenit.com/smple_upload_chain_smpp.csv"}
                            className="btn btn-secondary mt-3 float-right mr-3"
                          >
                            <span className="mr-2"><FontAwesomeIcon icon={faDownload} /></span>
                            Download Sample
                          </button>

                        </div>}
                    </div>
                    <div className="card-body">
                      {error.errarStatus ? (
                        <div className={error.bgcolor} role="alert">
                          <strong>Alert!</strong> {error.errorMessage}
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      ) : null}
                      <div className="row gy-3">
                        <div className={id ? "col-md-4" : "col-md-6"}>
                          {/* <Select
                            options={options}
                            name="selected-user"
                            value={selectedUser}
                            onChange={userHandler}
                            placeholder="Select Chain"
                            isSearchable
                          /> */}
                          {id && <label>Telemarketer ID</label>}
                          <div className="input-group">
                            <select className="form-select" name="chain" disabled={id ? true : false} value={tmID} onChange={userHandler}>
                              <option value="">Select TM ID</option>
                              {chainList.map((x) => (
                                <option key={x.entity_tm_id} value={x.entity_tm_id}>
                                  {x.entity_tm_id}
                                </option>
                              ))}
                            </select>

                          </div>
                        </div>
                        {!id &&
                          <div className="col-md-6">
                            <div className="input-group">
                              <input
                                type="file"
                                name="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                className="form-control"
                              />
                              <button
                                onClick={UploadCsv}
                                className="btn btn-primary"
                              >
                                <span className="mr-2"><FontAwesomeIcon icon={faUpload} /></span>
                                Upload
                              </button>
                            </div>
                          </div>}

                        {id && (
                          <>
                            <div className="col-md-4">
                              <label>Sender Entity ID</label>
                              <input type="text" name="chainid" value={senderEntityID} onChange={(e) => setSenderEntityID(e.target.value)} placeholder="Chain ID" className="form-control" />
                            </div>
                            <div className="col-md-4">
                              <label>Sender Chain ID</label>
                              <input type="text" name="senderchainid" value={senderchainID} onChange={(e) => setSenderChainID(e.target.value)} placeholder="Chain ID" className="form-control" />
                            </div>
                          </>
                        )}
                      </div>

                      {id &&
                        <div className="row gy-3 mt-2">
                          <div className="col-md-12">
                            <label>Mapping</label>
                            <textarea name="mapping" value={mapping} onChange={(e) => setMapping(e.target.value)} className="form-control" />
                            <span style={{ fontSize: "12px" }}><span style={{ fontWeight: "bold" }}>Note:</span> Enter comma seprated values</span>
                          </div>
                        </div>}

                        {id &&
                        <div className="row gy-3 mt-2">
                          <div className="col-md-6">
                            <label>Delivery Partner Id</label>
                            <input type="text" name="deliverypartnerid" value={deliveryPartnerId} onChange={(e) => setDeliveryPartnerId(e.target.value)} className="form-control" />
                          </div>
                        </div>}

                      {id &&
                        <div className="row gy-3 justify-content-center mt-2">
                          <div className="col-md-4">
                            <div className="input-group">
                              <button
                                onClick={UpdateList}
                                className="btn btn-block btn-primary me-1"
                              >
                                <span className="mr-2"><FontAwesomeIcon icon={faPen} /></span>
                                Update
                              </button>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </div>
               {dataList.length > 0 &&
                <div className="col-lg-12 col-md-4 order-1">
                  <div className="">
                    <div className="card">
                      <div className='row'>
                        <div className='col-md-6'>
                          <h5 className="card-header">Chain List</h5>
                        </div>
                        <div className='col-md-6'>
                          <div className="input-group mt-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text"><FontAwesomeIcon icon={faSearch} /></span>
                            </div>
                            <input
                              type="text"
                              id="searchInput"
                              name="searchdata"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              className="form-control mr-2"
                              placeholder="Search Message"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive text-nowrap">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Action</th>
                              <th>Tele Marketer ID</th>
                              <th>Sender Entity ID</th>
                              <th>Sender Chain ID</th>
                              <th>Mapping</th>
                              <th>TMDF1</th>
                              <th>Status </th>
                            </tr>
                          </thead>
                          <tbody className="table-border-bottom-0">
                            {currentRecords?.filter((x) => {
                              return (search.toLowerCase() === '' ? x : x.system_id_tmid.toLowerCase().includes(search) ||
                                search.toLowerCase() === '' ? x : x.sender_entity_id.toLowerCase().includes(search) ||
                                  search.toLowerCase() === '' ? x : x.sender_chain_id.toLowerCase().includes(search)
                              )
                            }).map((items, i) => (
                              <tr key={i}>
                                <td>
                                  <span style={{ cursor: "pointer" }} onClick={() => EditList(items.id, items.system_id_tmid, items.sender_entity_id, items.chain_mapping, items.sender_chain_id, items.TMDF1)} className="badge bg-primary mr-1"> <FontAwesomeIcon icon={faEdit} /> </span>
                                </td>
                                <td> {items.system_id_tmid}</td>
                                <td>{items.sender_entity_id}</td>
                                <td>{items.sender_chain_id}</td>
                                <td>{items.chain_mapping}</td>
                                <td>{items.TMDF1}</td>
                                <td>
                                  {items.status === 0 ? (
                                    <span className="badge badge-sm bg-danger mr-2">
                                      Inactive{" "}
                                    </span>
                                  ) : (
                                    <span className="badge badge-sm bg-success mr-2">
                                      Active{" "}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <ul className="pagination mt-2">
                      {Array.from({ length: totalPages }).map((_, index) => (
                        <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                          <button className="page-link" onClick={() => handlePagination(index + 1)}>
                            {index + 1}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadChain;
