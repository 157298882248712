import React, { useEffect, useState } from 'react'
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { token, type, userid } from '../../common/AdminAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPen, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { URL } from "../../common/Url";
import Select from 'react-select';

const AddTelemarketer = () => {
    const history = useHistory();
    const [dataItem, setDataItem] = useState({});
    const [status, setStatus] = useState(1);
    const [chainList, setChainList] = useState([]);
    // const [id, setId] = useState('');
    const [order, setOrder] = useState('ASC');
    const [selectedOption, setSelectedOption] = useState('');
    const [userList, setuserList] = useState([]);
    const [selectedUser,setSelectedUser] = useState(null);
    const {id,username, tmid, editstatus, telemarketerstatus } = useParams();

    const [error, seterror] = useState({
        errarStatus: false,
        errorMessage: "",
        bgcolor: "",
    });

      const userHandler = (selectedOption) => {
    setSelectedUser(selectedOption);
    seterror({ errarStatus: false })
  };

  const options = userList?.map((x) => ({
    value: x.username,
    label: x.username
  }));

    const handleChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        seterror({errarStatus: false})
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.value)
    }

    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
        seterror({ errarStatus: false });
    };

    useEffect(() => {
        getChainList();
        getUserDetails();
        EditEntityID();
    }, []);

    const getUserDetails = async () => {
        await fetch(
            `${URL}users-as.php?user_id=${userid}&method=retrieve_new&token=${token}&user_type=${type}`
        ).then((response) => {
            response.json().then((result) => {
                // console.log("user List", result);
                if (result.success === true) {
                    // console.log(result)
                    setuserList(result.data);
                }
            });
        });
    };

    const getChainList = async () => {
        let data = {
            user_id: userid,
            method: "retrieve_tmid_admin",
            token: token,
            user_type: type
        }
        try {
            let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            })
            let result = await response.json();

            if (result.success) {
                // console.log(result.data)
                setChainList(result.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const isValid = () => {
        if (!selectedUser) {
            seterror({
                errarStatus: true,
                errorMessage: "Please Select User",
                bgcolor: "aler alert-danger",
            });
            return false
        }
        if (dataItem.tmid === undefined || dataItem.tmid === "") {
            seterror({
                errarStatus: true,
                errorMessage: "Please Enter Telemarketer ID",
                bgcolor: "aler alert-danger",
            });
            return false
        }
        if (dataItem.tmid.length < 8) {
            seterror({
                errarStatus: true,
                errorMessage: "the Length should be gretaer than 8",
                bgcolor: "aler alert-danger",
            });
            return false
        }
        // if (dataItem.chainid === undefined || dataItem.chainid === "") {
        //   seterror({
        //     errarStatus: true,
        //     errorMessage: "Please Enter Chain ID",
        //     bgcolor: "aler alert-danger",
        //   });
        //   return false
        // }
        if (!selectedOption) {
            seterror({
                errarStatus: true,
                errorMessage: "Either Aggregator or Delivery Partner should be choose",
                bgcolor: "aler alert-danger",
            });
            return false
        }
        else {
            return true;
        }
    }

    const CreateChain = async () => {
        if (isValid()) {
            let data = {
                tmid: dataItem.tmid,
                chainid: dataItem.chainid ? dataItem.chainid : "",
                tmtype: selectedOption,
                status: status,
                user_id: userid,
                method: "add_tmid",
                token: token,
                ret_user_id: selectedUser.value,
                user_type: type
            }
            try {
                let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                let result = await response.json();
                if (result.success) {
                    seterror({
                        errarStatus: true,
                        errorMessage: result.message,
                        bgcolor: "aler alert-success",
                    });
                    setTimeout(() => {
                        seterror({ errarStatus: false });
                    }, 2000);
                    setTimeout(()=>{
                        history.push("/admin/telemarketer");
                    },2000);
                }
                else {
                    seterror({
                        errarStatus: true,
                        errorMessage: result.message,
                        bgcolor: "alert alert-danger",
                    });
                    setTimeout(() => {
                        seterror({ errarStatus: false });
                    }, 2000)
                }

            } catch (error) {
                console.log(error)
            }
        }
    }

    const EditEntityID = () => {
        setDataItem({
            ...dataItem,
            tmid: tmid,
        })
        setStatus(editstatus)
        setSelectedOption(telemarketerstatus?.toString());
        if(username){
            setSelectedUser({label:username , value: username})
        }
        else{
            setSelectedUser(null);
        }       
    }

    const UpdateChain = async () => {
        if (isValid()) {
            let data = {
                id: id,
                tmid: dataItem.tmid,
                chainid: dataItem.chainid ? dataItem.chainid : "",
                tmtype: selectedOption,
                status: status,
                user_id: userid,
                method: "update_tmid",
                token: token,
                ret_user_id: selectedUser.value,
                user_type: type
            }
            try {
                let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data),
                })
                let result = await response.json();
                if (result.success) {
                    seterror({
                        errarStatus: true,
                        errorMessage: result.message,
                        bgcolor: "aler alert-success",
                    });
                    setTimeout(() => {
                        seterror({ errarStatus: false });
                    }, 2000)
                    setTimeout(()=>{
                        history.push("/admin/telemarketer");
                    },2000)
                }
                else {
                    seterror({
                        errarStatus: true,
                        errorMessage: result.message,
                        bgcolor: "alert alert-danger",
                    });
                    setTimeout(() => {
                        seterror({ errarStatus: false });
                    }, 2000)
                }

            } catch (error) {
                console.log(error)
            }
        }
    }

    const GoBack = () => {
        history.push("/admin/telemarketer")
    }

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <AdminSideMenu />

                <div className="layout-page">
                    <AdminTopMenu />

                    <div className="content-wrapper">
                        <div className="container-xxl container-p-y">
                            <div className="row">
                                <div className='row'>
                                    <div className='col-md-6'></div>
                                    <div className='col-md-6'>
                                        <button type="button" onClick={GoBack} className="btn btn-secondary float-right mb-3">Go Back</button>
                                    </div>
                                </div>
                                <div className="col-xl-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Manage TMID</h5>
                                        <div className="card-body">
                                            {error.errarStatus ? (
                                                <div className={error.bgcolor} role="alert">
                                                    <strong>Alert!</strong> {error.errorMessage}
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="alert"
                                                        aria-label="Close"
                                                    >
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            ) : null}
                                            <div className="row gy-3">
                                                <div className="col-md-4">
                                                    <label>Select User</label>
                                                    <Select
                                                    options={options}
                                                    name="selected-user"
                                                    value={selectedUser}
                                                    onChange={userHandler}
                                                    placeholder="Select User"
                                                    isDisabled = {id ? true : false}
                                                    isSearchable
                                                />
                                                </div>

                                                <div className="col-md-4">
                                                    <label>Tele Marketer ID</label>
                                                    <input type="text" name="tmid" maxLength={20} defaultValue={dataItem.tmid} onChange={handleChange} placeholder="Tele Marketer ID" className="form-control" min="0" step="1" onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }} />
                                                </div>

                                                {/* <div className={chainList.length > 0 ? "col-md-6" : "col-md-4"}>
                          <label>Chain ID</label>
                          <input type="text" name="chainid" defaultValue={dataItem.chainid} onChange={handleChange} placeholder="Chain ID" className="form-control" />
                        </div> */}

                                                <div className="col-md-4">
                                                    <label>Status</label>
                                                    <div className="input-group">
                                                        <select className="form-select" value={status} onChange={handleStatusChange}>
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row gy-3 mt-2">
                                                <div className="col-md-6">
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="0"
                                                            checked={selectedOption === '0'}
                                                            onChange={handleRadioChange}
                                                        />
                                                        <span className="ml-1">Aggregator</span>
                                                    </label>

                                                    <label>
                                                        <input
                                                            type="radio"
                                                            value="1"
                                                            checked={selectedOption === '1'}
                                                            onChange={handleRadioChange}
                                                            className="ml-4"
                                                        />
                                                        <span className="ml-1">Delivery Partner</span>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row gy-3 justify-content-center mt-2">
                                                <div className="col-md-4">
                                                    {id ?
                                                        <div className="input-group">
                                                            <button
                                                                onClick={UpdateChain}
                                                                className="btn btn-block btn-primary me-1"
                                                            >
                                                                <span className="mr-2"><FontAwesomeIcon icon={faPen} /></span>
                                                                Update
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="input-group">
                                                            <button
                                                                onClick={CreateChain}
                                                                className="btn btn-block btn-primary me-1"
                                                            >
                                                                <span className="mr-2"><FontAwesomeIcon icon={faPlus} /></span>
                                                                Create
                                                            </button>
                                                        </div>}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTelemarketer
