import React, { useEffect, useState } from 'react'
import AdminSideMenu from "../../navbar/AdminSideMenu";
import AdminTopMenu from "../../navbar/AdminTopMenu";
import { token, type, userid } from '../../common/AdminAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEdit, faPen, faSearch, faUpload } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import { URL } from "../../common/Url";

const UploadChainAdmin = () => {
  const history = useHistory();
  const { id, username, tmid, entityid, chainmapping, chainid, deliverypatnerid } = useParams();
  const param = useParams();
  console.log(param)

  const [error, seterror] = useState({
    errarStatus: false,
    errorMessage: "",
    bgcolor: "",
  });
  // const [id, setID] = useState('');
  const [chainList, setChainList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [csvFile, setcsvFile] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [insideFileData, setInsideFileData] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [tmID, setTmID] = useState('');
  const [senderchainID, setSenderChainID] = useState('');
  const [senderEntityID, setSenderEntityID] = useState('');
  const [mapping, setMapping] = useState('');
  const [userList, setuserList] = useState([]);
  const [deliveryPartnerId , setDeliveryPartnerId] = useState('')

  const userHandler = (selectedOption) => {
    setSelectedUser(selectedOption);
    seterror({ errarStatus: false })
  };

  const handleChange = (e) => {
    setTmID(e.target.value);
    seterror({ errarStatus: false })
  };

  const options = userList.map((x) => ({
    value: x.username,
    label: x.username
  }));

  useEffect(() => {
    retrieveList();
    getChainList();
    getUserDetails();
    EditList();
  }, [])

  const EditList = () => {
    setTmID(tmid);
    setSenderEntityID(entityid);
    setSenderChainID(chainid);
    setMapping(chainmapping);
    setDeliveryPartnerId(deliverypatnerid);
    if (username) {
      setSelectedUser({ label: username, value: username })
    }
    else {
      setSelectedUser(null);
    }


  }

  const getUserDetails = async () => {
    await fetch(
      `${URL}users-as.php?user_id=${userid}&method=retrieve_new&token=${token}&user_type=${type}`
    ).then((response) => {
      response.json().then((result) => {
        // console.log("user List", result);
        if (result.success === true) {
          // console.log(result)
          setuserList(result.data);
        }
      });
    });
  };

  const getChainList = async () => {
    let data = {
      user_id: userid,
      method: "retrieve_tmid_admin",
      token: token,
      user_type: type
    }
    try {
      let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      let result = await response.json();

      if (result.success) {
        // console.log(result.data)
        setChainList(result?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const rows = text.split("\n").map((row) => row.trim()).filter(row => row.length > 0);  // Trim and remove empty rows

        // Optional: Process rows to extract headers and values
        const headers = rows[0].split(","); // Assuming the first row is the header
        const data = rows.slice(1).map((row) => {
          const values = row.split(",");
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = values[index];
          });
          return obj;
        });

        // console.log(data);
        setInsideFileData(data);
        setCsvData(file);
        setcsvFile(true); // Send data to parent component
      };

      reader.readAsText(file);
    }

    seterror({ errarStatus: false });
  };


  const retrieveList = async () => {
    let data = {
      user_id: userid,
      method: "retrieve_chain_admin",
      token: token,
      ret_user_id: userid,
      user_type: type
    }
    try {
      let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      let result = await response.json();

      if (result.success) {
        // console.log(result.data)
        setDataList(result.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const isValid = () => {
    if (!selectedUser) {
      seterror({
        errarStatus: true,
        errorMessage: "Please Select the User",
        bgcolor: "alert alert-danger",
      });
      return false
    }
    if (!tmID) {
      seterror({
        errarStatus: true,
        errorMessage: "Telemarketer ID should not be empty",
        bgcolor: "alert alert-danger",
      });
      return false
    }
    if (csvData.length === 0) {
      seterror({
        errarStatus: true,
        errorMessage: "Upload Csv Should not be empty",
        bgcolor: "alert alert-danger",
      });
      return false
    }
    // if (insideFileData.map((x) => x.EID === '' || x.EID === undefined) || insideFileData.map((x) => x.CHAINID === '' || x.CHAINID === undefined)) {
    //   seterror({
    //     errarStatus: true,
    //     errorMessage: "Telly marketer id and cahin id Should not be empty",
    //     bgcolor: "alert alert-danger",
    //   });
    //   return false
    // }
    else {
      return true;
    }
  }

  const UploadCsv = async () => {
    if (isValid()) {
      let formData = new FormData();

      const chainItem = chainList?.find(x => x.entity_tm_id === tmID);
      if (chainItem) {
        formData.append('tmid', `${tmID} & ${chainItem.delivery_partner}`);
      } else {
        formData.append('tmid', null);
      }
      formData.append('amdfile', csvData);
      formData.append('user_id', userid);
      formData.append('method', 'upload_chain');
      formData.append('token', token);
      formData.append('ret_user_id', selectedUser.value);
      formData.append('user_type', type);

      try {
        let response = await fetch("https://smsc.datagenit.com/apismpp/v1/dlt_setup.php", {
          method: 'POST',
          body: formData,
        });

        let result = await response.json();
        if (result.success) {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-success",
          });
          setTimeout(() => {
            seterror({ errarStatus: false });
          }, 3000);
          retrieveList();
          setTimeout(() => {
            history.push("/admin/upload-list")
          }, 1000);
        } else {
          seterror({
            errarStatus: true,
            errorMessage: result.message,
            bgcolor: "alert alert-danger",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  const UpdateList = async () => {
    // const chainItem = chainList?.find(x => x.entity_tm_id === senderEntityID);
    let data = {
      tmid: tmID,
      sender_entity_id: senderEntityID,
      sender_chain_id: senderchainID,
      mapping: mapping,
      tmdf1 : deliveryPartnerId,
      id: id,
      method: "update_chain",
      token: token,
      ret_user_id: username,
      user_type: type,
      user_id: userid,
    }
    try {
      let response = await fetch(`https://smsc.datagenit.com/apismpp/v1/dlt_setup.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      let result = await response.json();
      if (result.success) {
        seterror({
          errarStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-success",
        });
        retrieveList();
        setTimeout(() => {
          history.push("/admin/upload-list")
        }, 1000);
      }
      else {
        seterror({
          errarStatus: true,
          errorMessage: result.message,
          bgcolor: "alert alert-danger",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const GoBack = () => {
    history.push("/admin/upload-list")
  }

  console.log(chainList)

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <AdminSideMenu />

        <div className="layout-page">
          <AdminTopMenu />

          <div className="content-wrapper">
            <div className="container-xxl container-p-y">
              <div className="row">
                <div className="col-xl-12">
                  <div className='row'>
                    <div className='col-md-6'></div>
                    <div className='col-md-6'>
                      <button type="button" onClick={GoBack} className="btn btn-secondary float-right mb-3">Go Back</button>
                    </div>
                  </div>
                  <div className="card mb-4">
                    <div className="row">
                      <div className="col-6">
                        <h5 className="card-header">Upload Chain</h5>
                      </div>
                      {!id &&
                        <div className="col-6">
                          <button
                            onClick={() => window.location.href = "https://smsc.datagenit.com/smple_upload_chain_smpp.csv"}
                            className="btn btn-info mt-3 float-right mr-3"
                          >
                            <span className="mr-2"><FontAwesomeIcon icon={faDownload} /></span>
                            Download Sample
                          </button>

                        </div>}
                    </div>
                    <div className="card-body">
                      {error.errarStatus ? (
                        <div className={error.bgcolor} role="alert">
                          <strong>Alert!</strong> {error.errorMessage}
                          <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      ) : null}
                      <div className="row gy-3">
                        <div className={id ? 'col-md-6' : 'col-md-4'}>
                          {id && <label>Select User</label>}
                          <Select
                            options={options}
                            name="selected-user"
                            value={selectedUser}
                            onChange={userHandler}
                            placeholder="Select User"
                            isDisabled={id ? true : false}
                            isSearchable
                          />
                        </div>
                        <div className={id ? "col-md-6" : "col-md-4"}>
                          {/* <Select
                            options={options}
                            name="selected-user"
                            value={selectedUser}
                            onChange={userHandler}
                            placeholder="Select Chain"
                            isSearchable
                          /> */}
                          {id && <label>Telemarketer ID</label>}
                          <div className="input-group">
                            <select className="form-select" name="chain" disabled={id ? true : false} value={tmID} onChange={handleChange}>
                              <option value="">Select TM ID</option>

                              {/* Handle the specific condition where the selectedUser and system_id match */}
                              {chainList && selectedUser && selectedUser.value && (
                                chainList.map((x) => {
                                  if (x.system_id === selectedUser.value) {
                                    return (
                                      <option key={x.entity_tm_id} value={x.entity_tm_id}>
                                        {x.entity_tm_id}
                                      </option>
                                    );
                                  }
                                  return null;  // Return null for elements that do not match
                                })
                              )}

                              {/* Render all the options from chainList */}
                              {/* {chainList && chainList.map((x) => (
                                <option key={x.entity_tm_id} value={x.entity_tm_id}>
                                  {x.entity_tm_id}
                                </option>
                              ))} */}
                            </select>
                          </div>

                        </div>
                        {!id &&
                          <div className="col-md-4">
                            <div className="input-group">
                              <input
                                type="file"
                                name="file"
                                accept=".csv"
                                onChange={handleFileChange}
                                className="form-control"
                              />
                              <button
                                onClick={UploadCsv}
                                className="btn btn-primary"
                              >
                                <span className="mr-2"><FontAwesomeIcon icon={faUpload} /></span>
                                Upload
                              </button>
                            </div>
                          </div>}

                        {id && (
                          <>
                            <div className="col-md-6">
                              <label>Sender Entity ID</label>
                              <input type="text" name="chainid" defaultValue={senderEntityID} onChange={(e) => setSenderEntityID(e.target.value)} placeholder="Chain ID" className="form-control" />
                            </div>
                            <div className="col-md-6">
                              <label>Sender Chain ID</label>
                              <input type="text" name="senderchainid" defaultValue={senderchainID} onChange={(e) => setSenderChainID(e.target.value)} placeholder="Chain ID" className="form-control" />
                            </div>
                          </>
                        )}
                      </div>

                      {id &&
                        <div className="row gy-3 mt-2">
                          <div className="col-md-12">
                            <label>Mapping</label>
                            <textarea name="mapping" defaultValue={mapping} onChange={(e) => setMapping(e.target.value)} className="form-control" />
                            <span style={{ fontSize: "13px" }}><span style={{ fontWeight: "bold" }}>Note:</span> Enter comma seprated values</span>
                          </div>
                        </div>}

                        {id &&
                        <div className="row gy-3 mt-2">
                          <div className="col-md-6">
                            <label>Delivery Partner Id</label>
                            <input type='text' name='deliverypatnerid' value={deliveryPartnerId} onChange={(e)=>setDeliveryPartnerId(e.target.value)} className='form-control' />
                          </div>
                        </div>}

                      {id &&
                        <div className="row gy-3 justify-content-center mt-2">
                          <div className="col-md-4">
                            <div className="input-group">
                              <button
                                onClick={UpdateList}
                                className="btn btn-block btn-primary me-1"
                              >
                                <span className="mr-2"><FontAwesomeIcon icon={faPen} /></span>
                                Update
                              </button>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadChainAdmin
