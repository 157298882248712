import React, { useState, useEffect } from 'react';
import AdminSideMenu from '../../navbar/AdminSideMenu'
import AdminTopMenu from '../../navbar/AdminTopMenu'
import { URL } from '../../common/Url';
import { userid, token, type } from '../../common/AdminAuth';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import { customTableStylesForSummery } from '../../common/customTableStyles';

const BalanceHistory = () => {
    const [balanceHistory, setbalanceHistory] = useState([]);
    const [search,setSearch] = useState('')

    useEffect(() => {
        fetch(`${URL}add_balance.php?user_id=${userid}&method=trans_history&token=${token}&ret_user_id=&user_type=${type}`).then((response) => {
            response.json().then((result) => {
                if (result.success === true) {
                    console.log(result.data)
                    setbalanceHistory(result.data)
                }
            })
        })
    }, [])

    const columns = [
        {
            name: 'User Name',
            selector: row => row.username,
            sortable: true,

        },
        {
            name: 'Transaction Type',
            selector: row => row.transaction_id,
            sortable: true,

        },
        {
            name: 'Credit Amount ',
            selector: row => row.amount,
            sortable: true,

        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,
            wrap: true,
        },

        {
            name: 'Created',
            selector: row => row.created,
            sortable: true,
        },
    ];

    let balanceHistoryData = balanceHistory.filter((item)=>{
        return search.toLowerCase() === '' ? item : item.username.toLowerCase().includes(search) || item.created.toLowerCase().includes(search)
    })

    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">

                <AdminSideMenu />

                <div className="layout-page">

                    <AdminTopMenu />

                    <div className="content-wrapper">

                        <div className="container-xxl container-p-y">





                            <div className='mt-5'>
                                <div className="card">
                                    <div className="row gy-3">
                                        <div className="col-md-6">
                                            <h5 className="card-header">Balance History List</h5>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="input-group" style={{marginTop:'20px',marginLeft:"20vh"}}>
                                                <input
                                                    type="text"
                                                    name='search'
                                                    value={search}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                    className="form-control"
                                                    placeholder="Enter User Name"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">

                                        <DataTable
                                            pagination
                                            handleSort
                                            columns={columns}
                                            data={balanceHistoryData}
                                            customStyles={customTableStylesForSummery}
                                            noDataComponent="No balance History"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BalanceHistory;