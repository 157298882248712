function Alert(props) {
    return (
        <>
            {
                props.status ?
                    <div className={props.color} role="alert">
                        <strong>Alert!</strong> {props.message}
                        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    :
                    null
            }
        </>
    );
}

export default Alert;