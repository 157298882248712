import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { userid, token, type } from '../../common/AdminAuth';

const UserServerDetailPopup = ({sendDataToParent, username, viewuser}) => {
    const [serverDetails, setServerDetails] = useState(null); // Initialize state to null
    // const username = props.username;
    // const viewuser = props.viewuser;

    useEffect(() => {
        if (viewuser) { // Only fetch data if viewuser is true
            getServerDetails();
        }
    }, [viewuser]); // Run useEffect whenever viewuser changes
    const sendData = () => {
        console.log("hi");
        sendDataToParent(false); 
      };
    const getServerDetails = async () => {
        try {
            const response1 = await fetch(`https://smsc.datagenit.com/apismpp/v1/users-as.php?user_id=${userid}&method=retrieve_do&token=${token}&user_type=${type}&id=${username}`);
            const response2 = await fetch(`https://smsc.datagenit.com/apismpp/v1/users-as.php?user_id=${userid}&method=retrieve_ec2&token=${token}&user_type=${type}&id=${username}`);

            if (!response1.ok || !response2.ok) {
                throw new Error('Failed to fetch server details');
            }

            const data1 = await response1.json();
            const data2 = await response2.json();

            // Combine and set serverDetails
            setServerDetails([data1, data2]);
        } catch (error) {
            console.error('Error fetching server details:', error);
            setServerDetails([]); // Set empty array or handle error state
        }
    };

    return (
        <Modal show={viewuser} >
            <Modal.Header>
                <Modal.Title>User Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {serverDetails &&
                    serverDetails.map((item, index) =>
                        item.data && (
                            <div className="row" key={index}>
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header m-0">
                                            Status :{' '}
                                            {item.data[0].connect_allow_ip.includes('D') ? (
                                                <span className="text-danger">Inactive </span>
                                            ) : (
                                                <span className="text-success">Active </span>
                                            )}
                                        </h5>
                                        <hr className="m-0" />
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-3 mt-3">
                                                    <label htmlFor="defaultFormControlInput" className="form-label">
                                                        User Name
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="userName"
                                                        disabled={username ? true : false}
                                                        value={item.data[0].system_id}
                                                        placeholder="username"
                                                    />
                                                </div>
                                               {type === 'Admin' ? (
                                                   <div className="col-md-3 mt-3">
                                                   <label htmlFor="defaultFormControlInput" className="form-label">
                                                       Throughput(TPS)
                                                   </label>
                                                   <input
                                                       type="number"
                                                       className="form-control"
                                                       name="throughput"
                                                       disabled={username ? true : false}
                                                       value={item.data[0].throughput}
                                                       placeholder="Example : 1, 20"
                                                   />
                                               </div>
                                               ) : null}
                                               
                                                {type === 'Admin' ? (
                                                    <div className="col-md-3 mt-3">
                                                        <label htmlFor="defaultFormControlInput" className="form-label">
                                                            Default SMSC
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="throughput"
                                                            disabled={username ? true : false}
                                                            defaultValue={item.data[0].default_smsc}
                                                        />
                                                    </div>
                                                ) : null}

                                                <div className="col-md-3 mt-3">
                                                    <label htmlFor="defaultFormControlInput" className="form-label">
                                                        Billing Type
                                                    </label>
                                                    <select
                                                        name="enablePrepaid"
                                                        disabled={username ? true : false}
                                                        value={item.data[0].enable_prepaid_billing}
                                                        className="form-select"
                                                    >
                                                        <option>Select Billing </option>
                                                        <option value="1">Prepaid</option>
                                                        <option value="0">Postpaid</option>
                                                    </select>
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <label htmlFor="defaultFormControlInput" className="form-label">
                                                        Credit Amount
                                                    </label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        name="creditAmount"
                                                        disabled={username ? true : false}
                                                        value={item.data[0].credit}
                                                        placeholder="Amount 10000"
                                                    />
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <label htmlFor="defaultFormControlInput" className="form-label">
                                                        Max Binds
                                                    </label>
                                                    <select
                                                        name="maxBinds"
                                                        disabled={username ? true : false}
                                                        value={item.data[0].max_binds}
                                                        className="form-select"
                                                    >
                                                        <option>Max Binds </option>
                                                        {[...Array(10).keys()].map((num) => (
                                                            <option key={num + 1} value={num + 1}>
                                                                {num + 1}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className="col-md-4 mt-3">
                                                    <label style={{fontSize:"11px"}} htmlFor="defaultFormControlInput" className="form-label">
                                                        White list IP Address
                                                    </label>
                                                    <input
                                                        name="WhiteListIP"
                                                        value={item.data[0].connect_allow_ip}
                                                        type="text"
                                                        disabled={username ? true : false}
                                                        className="form-control"
                                                        placeholder="Example : 10.126.3.4"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
            </Modal.Body>
            <Modal.Footer>
            <button type="button" onClick={()=>sendData()} class="btn btn-secondary">Close</button>
            </Modal.Footer>
        </Modal>
    );
};

export default UserServerDetailPopup;
